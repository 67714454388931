import { default as _91page_935cqASIokdaMeta } from "/app/pages/[page].vue?macro=true";
import { default as about_45usWjawxlkv4gMeta } from "/app/pages/about-us.vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93OqczCn2NfnMeta } from "/app/pages/blog/categories/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as careersb3LI3USwU5Meta } from "/app/pages/careers.vue?macro=true";
import { default as communityWWXa42n3wkMeta } from "/app/pages/community.vue?macro=true";
import { default as contact_45uszp1HYnA7MsMeta } from "/app/pages/contact-us.vue?macro=true";
import { default as _91slug_93zBL8xT3xswMeta } from "/app/pages/deployment-types/[slug].vue?macro=true";
import { default as developerN1ldQe1j40Meta } from "/app/pages/developer.vue?macro=true";
import { default as _91slug_935xGnk5JrpsMeta } from "/app/pages/events/[slug].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as modulesphW7ERm3SXMeta } from "/app/pages/modules.vue?macro=true";
import { default as platformhlt8NvTQUHMeta } from "/app/pages/platform.vue?macro=true";
import { default as products08Bd1RJ93nMeta } from "/app/pages/products.vue?macro=true";
import { default as _91slug_93BW5Bk1aptcMeta } from "/app/pages/solutions/[slug].vue?macro=true";
import { default as waxctlGQxoaWvAZ4Meta } from "/app/pages/waxctl.vue?macro=true";
export default [
  {
    name: "page",
    path: "/:page()",
    component: () => import("/app/pages/[page].vue").then(m => m.default || m)
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-categories-slug",
    path: "/blog/categories/:slug()",
    component: () => import("/app/pages/blog/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/app/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "community",
    path: "/community",
    component: () => import("/app/pages/community.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "deployment-types-slug",
    path: "/deployment-types/:slug()",
    component: () => import("/app/pages/deployment-types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "developer",
    path: "/developer",
    component: () => import("/app/pages/developer.vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "modules",
    path: "/modules",
    meta: modulesphW7ERm3SXMeta || {},
    component: () => import("/app/pages/modules.vue").then(m => m.default || m)
  },
  {
    name: "platform",
    path: "/platform",
    component: () => import("/app/pages/platform.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/app/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "solutions-slug",
    path: "/solutions/:slug()",
    component: () => import("/app/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "waxctl",
    path: "/waxctl",
    component: () => import("/app/pages/waxctl.vue").then(m => m.default || m)
  }
]